import React from "react";
import LayoutNew from "../components/LayoutNew";
import { Helmet } from "react-helmet";

import HaveQuestion from "./new-pages/common/HaveQuestion";
import LastSection from "./new-pages/common/LastSection";
import MessageModal from "./new-pages/common/MessageModal";
import phoneImg1 from "../../static/images/phone-banner.png";
import bubbleIcon from "../../static/images/speech-bubble.png";
import CommonQuestionPhone from "./new-pages/common/CommonQuestionPhone";
import CommonQuestionInternet from "./new-pages/common/CommonQuestionInternet";
import CommonQuestionHome from "./new-pages/common/CommonQuestionHome";
import CommonQuestionTV from "./new-pages/common/CommonQuestionTV";

const FaqPage = () => {
    const [messagePopup, setMessagePopup] = React.useState(false); 
    return (
        <LayoutNew>
            <Helmet>
                <title>FAQs – Purple Cow Internet 💜🐄</title>
            </Helmet>
            <section className="phone-section1">
                <h1 className="h2">FAQs</h1>
                <img src={phoneImg1} alt="phone-banner" className="phone-banner-img" />
            </section>
            <CommonQuestionHome />
            <CommonQuestionInternet />
            <CommonQuestionTV />
            <CommonQuestionPhone />
           

            <HaveQuestion closepopup={setMessagePopup} />

            <LastSection title="Or if you're ready..." />

            <div className="contact-us-parent">
                <div className="contact-click-box" onClick={() => setMessagePopup(true)}>
                    <img src={bubbleIcon} alt="bubble" className="bubble-icon" />
                </div>
            </div>
            {messagePopup == true ? (<MessageModal closepopup={setMessagePopup}></MessageModal>) : null}
        </LayoutNew >
    )
}

export default FaqPage